import React from 'react';

const Service = ({title, description, image}) => {
  return (
    <>
        <div class="col-lg-4 col-md-6">
            <div class="single-feature wow fadeInUp" data-wow-delay=".2s">
                <div>
                  <img  style={{width:"100%", borderRadius:"10px", boxShadow: " 0px 4px 8px 0px white"}}src={image} alt="pic" />
                </div><br/>
                <hr/>
                <div class="content">
                  <h5>{title}</h5>
                  <p>{description}</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Service;
import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Products from "./Components/Products";
function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Products />
      <Contact />
      <section class="clients-logo-section pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="client-logo wow fadeInUp" data-wow-delay=".2s">
              <img src="assets/img/clients/brands.png" alt="" class="w-100" />
            </div>
          </div>
        </div>
      </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

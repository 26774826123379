import React from 'react'

const Header = () => {
  return (
    <>
      <div class="preloader">
        <div class="loader">
            <div class="spinner">
            <div class="spinner-container">
                <div class="spinner-rotator">
                <div class="spinner-left">
                    <div class="spinner-circle"></div>
                </div>
                <div class="spinner-right">
                    <div class="spinner-circle"></div>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>

      <section id="home" class="hero-section-wrapper-5">
            <header class="header header-6">
                <div class="navbar-area">
                <div class="container">
                    <div class="row align-items-center">
                    <div class="col-lg-12">
                        <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="/">
                            <img src="assets/img/logo/log.png" alt="Logo" />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent6" aria-controls="navbarSupportedContent6" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                        </button>
        
                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent6">
                            <ul id="nav6" class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <a class="page-scroll" href="#home">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="page-scroll" href="#about">About Us</a>
                                </li>
                                <li class="nav-item">
                                    <a class="page-scroll" href="#feature">Services</a>
                                </li>

                                <li class="nav-item">
                                    <a class="page-scroll" href="#contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        </nav>
                    </div>
                    </div>
                </div>
                </div>
            </header>
            <div class="hero-section hero-style-5 img-bg" style={{backgroundImage: "url('assets/img/hero/hero-5/back6.jpg')"}}>
                <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                    <div class="hero-content-wrapper">
                        <h2 style={{color:"white"}} class="mb-30 wow fadeInUp" data-wow-delay=".2s">Glosphene S.C</h2>
                        <p style={{color:"white", background: "grey", padding:"20px", borderRadius:"10px", boxShadow:"0 px 4px 8px 0px white", backgroundBlendMode: 'multiply'}}class="mb-30 wow fadeInUp" data-wow-delay=".4s">Use less and Get more</p>
                        <a href="#0" class="button button-lg radius-50 wow fadeInUp" data-wow-delay=".6s">Get Started <i class="lni lni-chevron-right"></i> </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
      </section>
    </>
  )
}

export default Header

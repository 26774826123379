import React from 'react';

const About = () => {
  return (
    <>
      <section id="about" class="about-section about-style-4">
        <div class="container">
            <div class="row align-items-center">
            <div class="col-xl-5 col-lg-6">
                <div class="about-content-wrapper">
                <div class="section-title mb-30">
                    <h3 class="mb-25 wow fadeInUp" data-wow-delay=".2s">The future Environmental Sustainability starts here!</h3>
                    <p class="wow fadeInUp" data-wow-delay=".3s">
                        <b>About Us:</b> Glosphene sustainability company was founded by Mr Wayne Madzimure in light of the obtaining global warming and climate
                        change. He recognized the need to help local mining, manufacturing, power and agricultural companies adopt green and sustainable
                        practices while increasing their profitability.
                    </p>
                </div>
                <ul>
                    <li class="wow fadeInUp" data-wow-delay=".35s">
                    <i class="lni lni-checkmark-circle"></i>
                    <b>Mission: </b> To mitigate global warming through providing sustainable solutions to mining, Agricultural, power and manufacturing industries.
                    </li>
                    <li class="wow fadeInUp" data-wow-delay=".4s">
                    <i class="lni lni-checkmark-circle"></i>
                    <b>Company Mantra: </b> use LESS, get MORE!
                    </li>
                </ul>
                <a href="#contact" class="button button-lg radius-10 wow fadeInUp" data-wow-delay=".5s">Contact Us <i class="lni lni-chevron-right"></i></a>
                </div>
            </div>
            <div class="col-xl-7 col-lg-6">
                <div class="about-image text-lg-right wow fadeInUp" data-wow-delay=".5s">
                <img style={{borderRadius:"10px", boxShadow:"0px 4px 8px 0px grey"}}src="assets/img/g/15.jpg" alt="" />
                </div>
            </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default About;

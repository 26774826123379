import React from 'react';
import emailjs from 'emailjs-com';



const Contact = () => {

    function sendMail(e){
        e.preventDefault();

        emailjs.sendForm('gmail', 'glosphene', e.target, 'HrzFRawEWdz_3HJOa')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        alert("Message successfully sent");
    }
  return (
    <>
      <section id="contact" class="contact-section contact-style-3">
        <div class="container">
            <div class="row justify-content-center">
            <div class="col-xxl-5 col-xl-5 col-lg-7 col-md-10">
                <div class="section-title text-center mb-50">
                <h3 class="mb-15">Get in touch</h3>
                <p>Do you have a query, or want to send us a message, just feel free to contact us.</p>
                </div>
            </div>
            </div>
            <div class="row">
            <div class="col-lg-8">
                <div class="contact-form-wrapper">
                <form onsubmit={sendMail} method="POST">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="single-input">
                        <input type="text" id="name" name="name" class="form-input" placeholder="Name" />
                        <i class="lni lni-user"></i>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="single-input">
                        <input type="email" id="email" name="email" class="form-input" placeholder="Email" />
                        <i class="lni lni-envelope"></i>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="single-input">
                        <input type="text" id="subject" name="subject" class="form-input" placeholder="Subject" />
                        <i class="lni lni-text-format"></i>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="single-input">
                        <textarea name="message" id="message" class="form-input" placeholder="Message" rows="6"></textarea>
                        <i class="lni lni-comments-alt"></i>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-button">
                        <button type="submit" class="button"> <i class="lni lni-telegram-original"></i> Send Message</button>
                        </div>
                    </div>
                    </div>
                </form>
                </div>

            </div>

            <div class="col-lg-4">
                <div class="left-wrapper">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                    <div class="single-item">
                        <div class="icon">
                        <i class="lni lni-phone"></i>
                        </div>
                        <div class="text">
                        <p>+263 788095159</p>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                    <div class="single-item">
                        <div class="icon">
                        <i class="lni lni-envelope"></i>
                        </div>
                        <div class="text">
                        <p>wamadzimure@gmail.com</p>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                    <div class="single-item">
                        <div class="icon">
                        <i class="lni lni-map-marker"></i>
                        </div>
                        <div class="text">
                        <p>591 Adylin Road West Gate, Harare<br /> Zimbabwe</p>
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Contact

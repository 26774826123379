import React from 'react';

const Footer = () => {
  return (
    <>
        <footer class="footer footer-style-4">
            <div class="container">
                <div class="widget-wrapper">
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6">
                    <div class="footer-widget wow fadeInUp" data-wow-delay=".2s">
                        <div class="logo">
                        <a href="#0"> <img src="assets/img/logo/log.png" style={{maxWidth: "250px", borderRadius: "10px"}}alt=""/> </a>
                        </div>
                        <p class="desc">Glosphene sustainability company was founded by Mr Wayne Madzimure in light of the obtaining global warming and climate
                        change. He recognized the need to help local mining, manufacturing, power and agricultural companies adopt green and sustainable
                        practices while increasing their profitability.
                        </p>
                        <ul class="socials">
                        <li> <a href="#0"> <i class="lni lni-facebook-filled"></i> </a> </li>
                        <li> <a href="#0"> <i class="lni lni-twitter-filled"></i> </a> </li>
                        <li> <a href="#0"> <i class="lni lni-instagram-filled"></i> </a> </li>
                        </ul>
                    </div>
                    </div>
                    <div class="col-xl-2 offset-xl-1 col-lg-2 col-md-6 col-sm-6">
                    <div class="footer-widget wow fadeInUp" data-wow-delay=".3s">
                        <h6>Quick Link</h6>
                        <ul class="links">
                        <li> <a href="/">Home</a> </li>
                        <li> <a href="#about">About</a> </li>
                        <li> <a href="#feature">Services</a> </li>
                        <li> <a href="#contact">Contact</a> </li>
                        </ul>
                    </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <div class="footer-widget wow fadeInUp" data-wow-delay=".4s">
                        <h6>Services</h6>
                        <ul class="links">
                            <li> <a href="#0">Mine Acid Drainage Treatment</a></li>
                            <li> <a href="#0">Energy Efficient Blasting Pattern</a></li>
                            <li> <a href="#0">Solar Installations</a></li>
                            <li> <a href="#0">Mine Water Abstraction</a></li>
                        </ul>
                        <a href="#feature" class="button  radius-10 wow fadeInUp" data-wow-delay=".5s">See more <i class="lni lni-chevron-right"></i></a>
                    </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6">
                    <div class="footer-widget wow fadeInUp" data-wow-delay=".5s">
                        <h6>SITEMAP</h6>
                    </div>
                    </div>
                </div>
                </div>
                <div class="copyright-wrapper wow fadeInUp" data-wow-delay=".2s">
                <p>Design and Developed by <a href="https://melgotech.co.zw" rel="noreferrer" target="_blank">Melgotech Inc</a></p>
                </div>
            </div>
        </footer>
        <a href="#0" class="scroll-top"> <i class="lni lni-chevron-up"></i> </a>

    </>
  )
}

export default Footer

import React from 'react';
import Service from './Service';

const Products = () => {
  return (
    <>
        <section id="feature" class="feature-section feature-style-5">
            <div class="container">
                <div class="row justify-content-center">
                <div class="col-xxl-5 col-xl-5 col-lg-7 col-md-8">
                    <div class="section-title text-center mb-60">
                    <h3 class="mb-15 wow fadeInUp" data-wow-delay=".2s">Specializing In</h3>
                    <p class="wow fadeInUp" data-wow-delay=".4s">Making the atmosphere cleaner through sustainable solutions to mining, agricultural, power and manufacturing industries.</p>
                    </div>
                </div>
                </div>

                <div class="row">
                    <Service
                        title="Mine Acid Drainage Treatment"
                        image="assets/img/g/2.jpg"
                        description=""
                    />
                    <Service
                        title="Energy Efficient Blasting Pattern"
                        image="assets/img/g/4.jpg"
                        description=""
                    />
                    <Service
                        title="Solar Installations"
                        image="assets/img/g/7.jpg"
                        description=""
                    />
                    <Service
                        title="Mine Water Abstraction"
                        image="assets/img/g/13.jpg"
                        description=""
                    />
                    <Service
                        title="Mine Equipment Overhaul"
                        image="assets/img/g/12.jpg"
                        description=""
                    />
                    <Service
                        title="Automated Irrigation Systems"
                        image="assets/img/g/1.jpg"
                        description=""
                    />
                    <Service
                        title="Crop Intensification assistance"
                        image="assets/img/g/5.jpg"
                        description=""
                    />
                    <Service
                        title="Dry Season Water Reservoirs"
                        image="assets/img/g/6.jpeg"
                        description=""
                    />
                    <Service
                        title="Soil Moisture sensor"
                        image="assets/img/g/16.jpg"
                        description=""
                    />
                </div>

            </div>
        </section>
    </>
  )
}

export default Products;
